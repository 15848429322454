<template>
  <div>
    <section class="dashboard">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Factura {{ no }}</h3>
              <form action="">
                <div class="row">
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Mesa</label>
                      <input
                        type="text"
                        :value="mesa"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Fecha</label>
                      <input
                        type="text"
                        class="form-control"
                        :value="getFormatDate"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">NCF</label>
                      <input
                        type="text"
                        :value="getNcf"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Sala</label>
                      <input
                        type="text"
                        class="form-control"
                        :value="sala"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Total Neto</label>
                      <input
                        type="text"
                        :value="numeral(totalneto).format('$ 0,0.00')"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Total Bruto</label>
                      <input
                        type="text"
                        :value="numeral(totalbruto).format('$ 0,0.00')"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div class="center">
                <h4>Productos</h4>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Vendedor</th>
                      <th>Fecha</th>
                      <th>Precio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="product in products" :key="product.Name">
                      <td>{{ product.descripcion }}</td>
                      <td>{{ product.cantidad }}</td>
                      <td>{{ product.vendedor }}</td>
                      <td>
                        {{ moment(product.fecha).format("DD/MM/YYYY hh:mm A") }}
                      </td>
                      <td>{{ numeral(product.monto).format("$ 0,0.00") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5>Metodos de pago</h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Metodo</th>
                      <th>Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="method in methodPayments"
                      :key="method.formapago"
                    >
                      <td>{{ method.formapago }}</td>
                      <td>{{ numeral(method.importe).format("$ 0,0.00") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import numeral from "numeral";
import axios from "axios";
import moment from "moment";
export default {
  name: "open-table-details",
  data() {
    return {
      items: [],
      mesa: "",
      fecha: "",
      vendedor: "",
      totalneto: 0,
      totalbruto: 0,
      seriefiscal: "",
      numerofiscal: "",
      sala: "",
      ncf: "",
      products: [],
      methodPayments: [],
    };
  },
  methods: {
    numeral: numeral,
    getTransaction() {
      axios(`/api/reports/transacts/${this.numero}/${this.serie}`).then(
        (res) => {
          this.methodPayments = res.data.data.ticketspaid;
          Object.entries(res.data.data).forEach(([key, value]) => {
            this[key] = value;
          });
        }
      );
    },
    getValues(key) {
      return this[key];
    },
    moment,
  },
  created() {
    this.getTransaction();
    this.$store.dispatch("setEvent", { name: "open_sales_details" });
    
  },
  computed: {
    numero: function () {
      return this.$route.params.id.split("|")[0];
    },
    serie: function () {
      return this.$route.params.id.split("|")[1];
    },
    no: function () {
      let id = this.$route.params.id.split("|");
      return id["0"] + " - " + id["1"];
    },
    getFormatDate: function () {
      return moment(this.fecha).format("DD/MM/YYYY hh:mm A");
    },
    getNcf() {
      return this.ncf.replace(/^0+/, "");
    },
  },
};
</script>